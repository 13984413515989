<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <b-input-group> <!-- Can't use .input-group-merge -->
      <flat-pickr
        :id="id"
        v-model="formValue"
        class="form-control"
        :placeholder="placeholder"
        :config="config"
        :readonly="readonly"
        :disabled="disabled"
      />
      <b-input-group-append is-text>
        <feather-icon
          icon="XIcon"
          class="cursor-pointer"
          @click="doClear"
        />
      </b-input-group-append>
    </b-input-group>
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormValidFeedback,
    BFormInvalidFeedback,
    flatPickr,
  },
  props: {
    // Got value from v-model
    value: {
      type: [String],
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    column: {
      type: String,
      required: false,
      default: '0',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    validFeedback: {
      type: String,
      required: false,
      default: '',
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: '',
    },
    enableTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxDate: {
      type: String,
      default: '',
    },
    minDate: {
      type: String,
      default: '',
    },
    disableDates: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    config() {
      return {
        static: true,
        altInput: true,
        altFormat: 'j F Y',
        dateFormat: 'Y-m-d',
        enableTime: (this.minDate ? this.minDate : this.enableTime),
        time_24hr: true,
        hourIncrement: 1,
        minuteIncrement: 1,
        maxDate: this.maxDate,
        minDate: this.minDate,
        disable: this.disableDates,
      }
    },
  },
  methods: {
    doClear() {
      // if (!this.readonly && !this.disabled) {
      this.$emit('input', '')
      // }
    },
  },
}
</script>
